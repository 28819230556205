<template>
  <mds-layout-grid>
    <filter-section />
    <header-section />
    <main-section-pr-ec />
  </mds-layout-grid>
</template>

<script>
import FilterSection from '@/components/collection-pr-ec/filter/FilterSection.vue';
import HeaderSection from '@/components/collection-pr-ec/header/HeaderSection.vue';
import MainSectionPrEc from '@/components/collection-pr-ec/MainSectionPrEc.vue';

export default {
  name: 'CollectionPrEcPage',
  components: {
    FilterSection,
    HeaderSection,
    MainSectionPrEc,
  },
};
</script>
