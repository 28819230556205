<template>
  <mds-row
    align-horizontal="space-between"
  >
    <mds-col :cols="4">
      <h2 class="parsers-table__title">
        {{ this.$t(`collectionPrEcPage.title`) }}:
      </h2>
    </mds-col>
    <mds-col
      :cols="2"
    >
      <span class="parsers-table__refresh-label">Refresh</span>
      <mds-button
        variation="icon-only"
        class="parsers-table__refresh"
        icon="refresh"
        type="button"
        text="Refresh"
        :disabled="disableRefresh"
        @click="refreshData"
      />
    </mds-col>
  </mds-row>
</template>

<script>
import { MdsButton } from '@mds/button';
import { MODULE, COMMON } from '@/constants/store.constant';

export default {
  name: 'HeaderSection',
  components: {
    MdsButton,
  },
  data () {
    return {
      disableRefresh: false,
    };
  },
  methods: {
    async refreshData () {
      this.$store.commit(`${MODULE.COLLECTION_PR_EC_MODULE}/${COMMON.UPDATE_STATE}`, { name: 'shouldRefresh', value: true });
      this.disableRefresh = true;
      setTimeout(() => {
        this.disableRefresh = false;
      }, 5000);
    },
  },
};

</script>

<style lang="scss" scoped>
@import '@mds/typography';

.parsers-table {
  &__title {
    @include mds-level-3-heading($bold: true);
  }
  &__refresh {
    margin-top: $mds-space-3-x;
    margin-right: $mds-space-2-x;
  }
  &__refresh-label {
    @include mds-body-text-m();
    margin-right: $mds-space-1-and-a-half-x;
    vertical-align: super;
    font-size: $mds-space-1-and-a-half-x;
    font-weight: 600;
  }
}
</style>
