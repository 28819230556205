export const PROVIDER_EXECUTIONS_HEADERS = [
  {
    fieldName: 'translatedName',
    text: 'Company Name',
    style: {
      width: '20%',
    },
  },
  {
    fieldName: 'companyId',
    text: 'Company ID',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'processUuid',
    text: 'Process UUID',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'link',
    text: 'Link',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'indexType',
    text: 'Type',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'frequency',
    text: 'Frequency',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'processStartTime',
    text: 'Start Time (UTC)',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'processEndTime',
    text: 'End Time (UTC)',
    style: {
      width: '15%',
    },
  },
  {
    fieldName: 'status',
    text: 'Status',
    style: {
      width: '10%',
    },
  },
  {
    fieldName: 'errorMessage',
    text: 'Error Message',
    style: {
      width: '8%',
    },
  }
];
