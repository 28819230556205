<template>
  <div>
    <mds-row
      align-horizontal="space-between"
      class="filter"
    >
      <mds-col :cols="6">
        <mds-search-field
          v-model="filterText"
          :placeholder="this.$t(`collectionPrEcPage.filterLabel`)"
          class="filter__search"
          label="Search"
        />
      </mds-col>
    </mds-row>
  </div>
</template>

<script>
import MdsSearchField from '@mds/search-field';
import { MODULE, COMMON } from '@/constants/store.constant';
import debounce from 'lodash.debounce';

export default {
  name: 'FilterSection',
  components: {
    MdsSearchField,
  },
  computed: {
    filterText: {
      get: function () {
        return this.$store.getters[`${MODULE.COLLECTION_PR_EC_MODULE}/${COMMON.GET_STATE_BY_NAME}`]('filterText') ?? '';
      },
      set: function (newValue) {
        this.$store.commit(`${MODULE.COLLECTION_PR_EC_MODULE}/${COMMON.UPDATE_STATE}`, { name: 'filterText', value: newValue });
        this.$store.dispatch(`${MODULE.COLLECTION_PR_EC_MODULE}/${COMMON.FILTER_DATA}`);
      },
    },
  },
  methods: {
    fetchEMCollection: debounce(async function () {
      this.$store.commit(`${MODULE.COLLECTION_PR_EC_MODULE}/${COMMON.UPDATE_PARAM}`, { name: 'name', value: this.filterText });
      await this.$store.dispatch(`${MODULE.COLLECTION_PR_EC_MODULE}/${COMMON.FILTER_DATA}`, 'name');
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
@import '@mds/typography';
.filter {
  &__search {
    width: 300px;
    margin-top: $mds-space-3-x;
  }
}
</style>
