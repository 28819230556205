<template>
  <mds-row>
    <mds-col>
      <store-provider
        v-bind="$options.storeInfo"
        is-paged
      >
        <template v-slot="{ state: filterJobs }">
          <last-job-executions-table
            :filter-jobs="filterJobs"
          />
        </template>
      </store-provider>
    </mds-col>
  </mds-row>
</template>

<script>
import StoreProvider from '@/components/common/StoreProvider.vue';
import LastJobExecutionsTable from '@/components/collection-pr-ec/table/LastJobExecutionsTable.vue';
import { MODULE, COMMON } from '@/constants/store.constant';

export default {
  name: 'MainSectionPrEc',
  components: {
    StoreProvider,
    LastJobExecutionsTable,
  },
  storeInfo: {
    module: MODULE.COLLECTION_PR_EC_MODULE,
    action: COMMON.FETCH_DATA,
    getter: COMMON.GET_STATE,
  },
};
</script>
