<template>
  <mds-table row-hover>
    <mds-thead>
      <mds-th
        v-for="header in $options.headers"
        :key="header.fieldName"
        :style="header.style"
      >
        {{ header.text }}
      </mds-th>
    </mds-thead>
    <mds-tbody>
      <template
        v-for="(row, index) in filterJobs"
      >
        <mds-tr
          :key="index"
          :aria-controls="index+1"
        >
          <mds-td
            v-for="(header, i) in $options.headers"
            :key="i"
            :right-aligned="header.align === 'right'"
            :style="header.style"
          >
            <template v-if="header.fieldName === 'status'">
              <state-tag
                :status="row[header.fieldName]"
                :color="getTagColor(row[header.fieldName])"
              />
            </template>
            <template v-else-if="header.fieldName === 'link'">
              <a
                :href="row.link"
                target="_blank"
              >
                {{ row.link | decodeLink }}
              </a>
            </template>
            <template v-else-if="header.fieldName === 'processStartTime' || header.fieldName === 'processEndTime'">
              {{ row[header.fieldName] | formatDate }}
            </template>
            <template v-else-if="header.fieldName === 'indexType' || header.fieldName === 'frequency'">
              {{ row[header.fieldName] | textFormatter }}
            </template>
            <template v-else-if="header.fieldName === 'errorMessage'">
              <template v-if="row[header.fieldName]">
                <mds-button
                  :id="`prompt-trigger-error${index}`"
                  variation="icon-only"
                  icon="open-new"
                  type="button"
                  text="Copy"
                  @click="copyErrorToClipboard(index, row[header.fieldName])"
                />
                <mds-tooltip
                  v-model="copiedError[index]"
                  :triggered-by="`prompt-trigger-error${index}`"
                  :position="['right-center']"
                  class="crawled-documents__tooltip"
                >
                  {{ $t(`collectionPrEcPage.copied`) }}
                </mds-tooltip>
              </template>
            </template>
            <template v-else>
              {{ row[header.fieldName] }}
            </template>
          </mds-td>
        </mds-tr>
      </template>
    </mds-tbody>
  </mds-table>
</template>

<script>
import { PROVIDER_EXECUTIONS_HEADERS } from '@/constants/pr-ec.constant';
import { STAGES, STATUS } from '@/constants/status-tags-colors.constant';
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table';
import { MdsButton } from '@mds/button';
import MdsTooltip from '@mds/tooltip';
import StateTag from '@/components/ui/StateTag';
import { formatDate, textFormatter } from '@/utils/global.util';

export default {
  name: 'LastJobExecutionsTable',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsButton,
    MdsTooltip,
    StateTag,
  },
  filters: {
    formatDate,
    textFormatter,
    decodeLink (url) {
      return decodeURI(url);
    },
  },
  props: {
    filterJobs: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      copied: {},
      copiedError: {},
    };
  },
  methods: {
    getTagColor (text) {
      return STAGES[text] || STATUS[text] || '';
    },
    async copyErrorToClipboard (id, text) {
      await navigator.clipboard.writeText(text);
      this.$set(this.copiedError, id, true);
      setTimeout(() => {
        this.$set(this.copiedError, id, false);
      }, 2500);
    },
  },
  headers: PROVIDER_EXECUTIONS_HEADERS,
};
</script>
